import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { Loader } from "./components/loader";
import { FMNavbar } from "./components/nav-bar";
import { ProtectedRoute } from "./components/protected-route";
import { SymbolsList } from "./pages/symbols-list";
import { Home } from "./pages/home";
import { NotFound } from "./pages/not-found";
import { SymbolsAdd } from "./pages/symbols-add";

export const App: React.FC = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <Loader />
      </div>
    );
  }

  return (
    <div className="page-layout">
      <FMNavbar />
      <Switch>
        <Route path="/" exact component={Home} />
        <ProtectedRoute exact path="/symbols" component={SymbolsList} />
        <ProtectedRoute path="/symbols/add" component={SymbolsAdd} />
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  );
};
