import { Hero, Section } from "react-bulma-components";

export const NotFound = () => {
  return (
    <Section>
      <Hero>
        <Hero.Header>
          <strong>Not Found</strong>
        </Hero.Header>
      </Hero>
    </Section>
  );
};
