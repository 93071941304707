import React, { useEffect } from 'react';
import bulmaCalendar, { EventType } from 'bulma-calendar';
import "bulma-calendar/dist/css/bulma-calendar.min.css"
// import { BulmaComponent } from 'react-bulma-components/src/components';
import { Moment } from 'moment-timezone';

type StripArray<T> = T extends Array<infer R> ? R : never;
// type StripArray<T> = infer R extends T[keyof T]
type BulmaCalendar = StripArray<ReturnType<typeof bulmaCalendar.attach>>;
// const __bcft = () => { return bulmaCalendar.attach('')[0] }
// type BulmaCalendar = ReturnType<typeof __bcft>;

export function DatePicker({
  placeholder,
  value,
  onChange
}: {
  placeholder: string,
  value: Moment,
  onChange: ((e: bulmaCalendar.Event<bulmaCalendar.EventType>) => void)
}) {
  const id = `id-${Math.floor(Math.random() * 100000)}`
  useEffect(() => {
    // X.
    // Initialize all input of date type.
    const calendars = bulmaCalendar.attach(`[id="${id}"]`, {
      // onReady: function () {
      //   let element = document.querySelector('#' + id);
      //   if (element) {
      //     console.log((element as any).value);c:\Program Files\Microsoft VS Code\resources\app\out\vs\code\electron-sandbox\workbench\workbench.html
      //     (element as any).value = value.format('DD-MM-YYYY');
      //     console.log((element as any).value);
      //     // const calendar = ((element as any).bulmaCalendar as BulmaCalendar)
      //     // console.log(calendar)
      //     // if (calendar) {
      //       // calendar.value(value.format('DD-MM-YYYY'))
      //     // }
      //   }
      // }
      // // value: '100'
    });

    // Loop on each calendar initialized
    calendars.forEach((calendar) => {
      // Add listener to date:selected event
      // console.log(calendar.id)
      // if (calendar.id === id) {
      calendar.value(value.toDate() as any)
      // calendar.on('date:selected' as EventType, (date) => {
      //   console.log(date);
      //   onChange(date)
      // });
      // }
    });

    // To access to bulmaCalendar instance of an element
    // eslint-disable-next-line no-undef
    const element = document.querySelector('#' + id);
    let calendar: BulmaCalendar | null = null
    if (element) {
      calendar = ((element as any).bulmaCalendar as BulmaCalendar)
      // calendar.
      // bulmaCalendar instance is available as element.bulmaCalendar
      calendar.on('select', (datepicker: any) => {
        // console.log(datepicker.data.value());
        onChange(datepicker)
      });
    }
    return () => {
      if (calendar) {
        calendar.on('select', () => { })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div >
      <input placeholder={placeholder} id={id} value={value.format('DD-MM-YYYY')} type="date" onChange={() => { }} />
    </div>
  );
}


// export default Dob;