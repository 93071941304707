import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button } from "react-bulma-components";

export const LoginButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      // className="button is-primary is-small"
      color="primary" size="small"
      onClick={() => loginWithRedirect()}
    >
      Log In
    </Button>
  );
};
