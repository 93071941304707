import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useEnv } from "../context/env.context";
import { Option } from "../models/option" 


export const useSymbolsMonitorApi = () => {

  const { getAccessTokenSilently } = useAuth0();
  const { apiServerUrl } = useEnv();

  const makeRequest = async (options: {
    config: AxiosRequestConfig;
    unauthenticated?: boolean;
  }) => {
    try {
      if (!options.unauthenticated) {
        const token = await getAccessTokenSilently();

        options.config.headers = {
          ...options.config.headers,
          Authorization: `Bearer ${token}`,
        };
      }

      const response: AxiosResponse = await axios(options.config);
      const { data } = response;

      return data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        throw error.response.data;
      }

      // throw (error as Error).message;
      throw error;
    }
  };

  const getOptions = async(): Promise<[Option[], Error?]> => {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/symbols/options`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    try {
      const data = await makeRequest({ config });
      // console.log("DATA!: ", data)
      return [data.results.map((x: any) => Option.fromJSON(x)), undefined];
    } catch(err) {
      return [[], err as Error]
    }
  };

  const addOption = async(option: Option): Promise<[string?, Error?]> => {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/api/symbols/options`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      data: option.toJSON()
    };

    try {
      const data = await makeRequest({ config });
      // console.log("DATA!: ", data)
      return [data, undefined];
    } catch(err) {
      return [undefined, err as Error]
    }
  }

  return {
    getOptions,
    addOption
  };
};
