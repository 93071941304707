import moment, { Moment } from "moment-timezone"

export enum OptionTypes {
    'put' = 'put',
    'call' = 'call'
};

export type OptionType = keyof typeof OptionTypes;

export const ALL_STRIKEPRICES = '*';

export class Option {
    option_type: OptionType;
    underlying_name: string;
    expiration_date: Moment;
    strike_price: number | typeof ALL_STRIKEPRICES;
    constructor($option_type: 'put' | 'call', $underlying_name: string, $expiration_date: string, $strike_price: string) {
        this.option_type = $option_type;
        this.underlying_name = $underlying_name;
        this.expiration_date = moment(Number($expiration_date) * 1000);
        if($strike_price !== ALL_STRIKEPRICES) {
            this.strike_price = Number($strike_price);
        } else {
            this.strike_price = $strike_price;
        }
    }
    toJSON(): object {
        return {
            option_type: this.option_type,
            underlying_name: this.underlying_name,
            expiration_date: this.expiration_date.unix(),
            strike_price: this.strike_price
        }
    }
    static fromJSON(jsonObj: any) {
        return new Option(jsonObj.option_type, jsonObj.underlying_name, jsonObj.expiration_date, jsonObj.strike_price);
    }
}