import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { AuthenticationButton } from "./buttons/authentication-button";
import { Navbar } from "react-bulma-components"
import logo from "../logo.jpg";

export const FMNavbar: React.FC = () => {
  const [isActive, setIsActive] = useState(false);
  return (
    <Navbar>
      <Navbar.Brand>
        <Navbar.Item href="#">
          <img
            alt="iLlUmInaTi c0Nf1rMeD"
            height="28"
            src={logo}
            width="28"
          />
        </Navbar.Item>
        <Navbar.Burger onClick={() => {setIsActive(!isActive)}} />
      </Navbar.Brand>
      <Navbar.Menu className={isActive ? "is-active" : ""}>
        <Navbar.Container>
          <Navbar.Item renderAs={NavLink} to="/">
            Home
          </Navbar.Item>
          <Navbar.Item renderAs={NavLink} to="/symbols">List Symbols</Navbar.Item>
          <Navbar.Item renderAs={NavLink} to="/symbols/add">Add Symbol</Navbar.Item>
        </Navbar.Container>
        <Navbar.Container align="right">
          <Navbar.Item href="#">
            <AuthenticationButton />
          </Navbar.Item>
        </Navbar.Container>
      </Navbar.Menu>
    </Navbar>
  )
};
