import { useState } from "react";
import { Box, Section, Button, Form, Block, Tabs, Modal, Content } from "react-bulma-components";
import { NavLink } from "react-router-dom";
import { useSymbolsMonitorApi } from "../hooks/use-symbols-monitor-api";
import { Option, OptionType, OptionTypes } from "../models/option"
import { DatePicker } from "../components/datepicker";
import moment, { Moment } from "moment";

const OptionAdd = () => {
  const {
    addOption
  } = useSymbolsMonitorApi();

  async function submitAdd() {
    let sp = allStrikePrices ? '*' : strikePrice;
    let reqOption = new Option(optionType, name, String(expirationDate.unix()), String(sp));
    console.log(JSON.stringify(reqOption))
    try {
      let reqRes = await addOption(reqOption);
      // let reqRes: [string | undefined, Error | undefined] = ["Done!", undefined];
      // let reqRes = [undefined, new Error("BIG ERROR, OOF")];
      if (reqRes[1]) {
        setSubmitErr(reqRes[1]);
      } else {
        setSubmitErr(undefined);
      }
    } catch (err) {
      setSubmitErr(err as Error);
    } finally {
      setIsSubmitting(false);
      setDidSubmit(true);
    }
  }

  const [optionType, setOptionType] = useState<OptionType>(OptionTypes.call);
  const [name, setName] = useState<string>("");
  const [expirationDate, setExpirationDate] = useState<Moment>(moment());
  const [strikePrice, setStrikePrice] = useState(0);
  const [allStrikePrices, setAllStrikePrices] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [didSubmit, setDidSubmit] = useState(false);
  const [submitErr, setSubmitErr] = useState<Error | undefined>(undefined);

  const onOptionTypeChange = (e: any) => {
    return setOptionType(e.target.value);
  };

  return (
    <form onSubmit={(e: any) => e.preventDefault()}>
      <Block renderAs="fieldset" disabled={isSubmitting}>
        <Form.Field>
          <Form.Label>Option Type</Form.Label>
          <Form.Control>
            <Form.Radio //TODO: Use switch - https://www.npmjs.com/package/bulma-switch
              value={OptionTypes.call}
              name="story-radio-name"
              checked={optionType === OptionTypes.call}
              onChange={onOptionTypeChange}
            >
              Call
            </Form.Radio>
            <Form.Radio
              value={OptionTypes.put}
              name="story-radio-name"
              checked={optionType === OptionTypes.put}
              onChange={onOptionTypeChange}
            >
              Put
            </Form.Radio>
          </Form.Control>
        </Form.Field>

        {
          // TODO: Add autocomplete to symbols:
          // see here: https://codesandbox.io/s/bulma-autocomplete-gm3pd?file=/src/Autocomplete.jsx 
        }

        <Form.Field>
          <Form.Label>Underlying Symbol</Form.Label>
          <Form.Control>
            <Form.Input
              color="primary"
              placeholder="e.g. TSLA"
              value={name}
              onChange={(e) => {
                return setName(e.target.value);
              }}
            />
          </Form.Control>
        </Form.Field>

        <Form.Field>
          <Form.Label>Expiration Date</Form.Label>
          <Form.Control>
            <DatePicker placeholder="e.g. 01/20/2023" value={expirationDate} onChange={(e) => {
              let dateString = e.data.value();
              let fullDateString = `${dateString}_00:00:00`;
              let fmtString = "MM/DD/YYYY_h:mm:ss"
              // console.log('Evt hdlr: ', e.data.value())
              let selectedDate = moment.tz(fullDateString, fmtString, "GMT")
              console.log(selectedDate.unix())
              return setExpirationDate(selectedDate)
            }} />
          </Form.Control>
        </Form.Field>

        <Form.Field>
          <Form.Control>
            <Form.Checkbox
              defaultChecked={!!allStrikePrices}
              onChange={(e) => {
                return setAllStrikePrices(!allStrikePrices)
              }}
            >
              Record <strong>ALL</strong> strike prices
            </Form.Checkbox>
          </Form.Control>
        </Form.Field>

        <Form.Field>
          <Form.Label>Strike Price</Form.Label>
          <Form.Control>
            <Form.Input
              placeholder="e.g. TSLA"
              disabled={allStrikePrices}
              value={strikePrice}
              onChange={(e) => {
                return setStrikePrice(Number(e.target.value))
              }}
            />
          </Form.Control>
        </Form.Field>


        <Form.Field kind="group">
          <Form.Control>
            <Button color="link" onClick={() => { console.log('Submit!'); setIsSubmitting(true); submitAdd(); }}>Submit</Button>
          </Form.Control>
          <Form.Control>
            <Button color="link" colorVariant="light" renderAs={NavLink} to="/symbols">
              Cancel
            </Button>
          </Form.Control>
        </Form.Field>
      </Block>
      <Modal
        // onClose={() => { setDidSubmit(false) }}
        show={didSubmit}
      >
        <Modal.Card>
          <Modal.Card.Header>
            <Modal.Card.Title>
              {submitErr ? "Error" : "Success"}
            </Modal.Card.Title>
          </Modal.Card.Header>
          <Modal.Card.Body>
            <Content>
              <p>
                {submitErr ? submitErr.message : "Successfully added option"}
              </p>
            </Content>
          </Modal.Card.Body>
          <Modal.Card.Footer>
            <Button color="info" onClick={() => setDidSubmit(!didSubmit)}>
              Close
            </Button>
          </Modal.Card.Footer>
        </Modal.Card>
      </Modal>
    </form>
  )
}

const StockAdd = () => {
  return (
    <h1> Not implemented! </h1>
  )
}

const TabSet = (props: { activeTab: number }) => {
  const TABS = [(<OptionAdd></OptionAdd>), (<StockAdd></StockAdd>)]
  // const [activeTab, setActiveTab] = useState(0);

  return TABS[props.activeTab];
}

export const SymbolsAdd = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Section>
      <div className="container">
        <Box>
          <Tabs>
            <Tabs.Tab active={activeTab === 0} onClick={() => setActiveTab(0)}>
              Option
            </Tabs.Tab>
            <Tabs.Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>
              Stock
            </Tabs.Tab>
          </Tabs>
          <TabSet activeTab={activeTab} />
        </Box>
      </div>
    </Section>
  );
};
