import { Section, Hero } from "react-bulma-components";
export const Home = () => (
  <Section>
    <Hero>
      <Hero.Header>
        <strong>Use this portal to add or remove symbols to monitor prices for.</strong>
      </Hero.Header>
    </Hero>
  </Section>
);
