import { useEffect, useState } from "react";
import { Box, Section, Table, Hero, Container, Button } from "react-bulma-components";
import { useSymbolsMonitorApi } from "../hooks/use-symbols-monitor-api";
import { Option, ALL_STRIKEPRICES } from "../models/option"

export const SymbolsList = () => {
  const REFRESH_INTVL = 30 * 1000;

  const {
    getOptions
  } = useSymbolsMonitorApi();

  const [options, updateOptionsList] = useState<Option[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  function renderStrikePrice(sp: number | typeof ALL_STRIKEPRICES) {
    if(sp === ALL_STRIKEPRICES) {
      return 'ALL'
    } else {
      return sp
    }
  }

  useEffect(() => {
    let isInUse = true;

    const refreshOptions = async () => {
      if (!isInUse) return;
      if (loading) return;
      setLoading(true);
      let newOpts = await getOptions();
      console.log('Got new opts')
      if (!isInUse) return;
      if (newOpts[1]) {
        setError(newOpts[1])
      } else {
        console.log('Set new opts')
        setError(undefined)
        updateOptionsList(newOpts[0]);
      }
      setLoading(false);
    }

    function ro() {
      refreshOptions().then(() => { }).finally(() => { if (isInUse) { setTimeout(ro, REFRESH_INTVL); console.log('setTimeout') } });
    };
    ro();
    return () => {
      console.log('Destructor called');
      isInUse = false
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ?
    (
      <Hero>
        <Hero.Body>
          <Container>
            <h1 className="title">Loading...</h1>
          </Container>
        </Hero.Body>
      </Hero>
    ) : (
      error ?
        (
          <Hero>
            <Hero.Body>
              <Container>
                <h1 className="title">{error.name}:</h1>
                <h2 className="subtitle">{error.message}</h2>
              </Container>
            </Hero.Body>
          </Hero>
        ) : (
          <Section>
            <div className="container">
              <Box>
                <Table>
                  <thead>
                    <tr>
                      <th><abbr title="'put' or 'call'">Option Type</abbr></th>
                      <th>Underlying Symbol</th>
                      <th>Expiration Date <button className="delete"></button></th>
                      <th>Strike Price</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      options.map((opt) =>
                        <tr key={opt.option_type + opt.underlying_name + opt.expiration_date + opt.strike_price}>
                          <td>{opt.option_type}</td>
                          <td>{opt.underlying_name}</td>
                          <td>{opt.expiration_date.utc().format('DD-MM-YYYY')}</td>
                          <td>{renderStrikePrice(opt.strike_price)}</td>
                          <td>
                            <Button color="danger" outlined={true} disabled={true}>
                              <span>Delete</span>
                            </Button>
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </Table>
              </Box>
            </div>
          </Section>
        ));
};
