import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button } from "react-bulma-components";

export const LogoutButton: React.FC = () => {
  const { logout } = useAuth0();

  return (
    <Button
      // className="button is-primary is-small"
      color="primary" size="small"
      onClick={() =>
        logout({
          returnTo: window.location.origin,
        })
      }
    >
      Log Out
    </Button>
  );
};
